import React from 'react';
import Link from 'gatsby-link';

function Serviceslinks(props) {
    return (
        <div>
            <ul>
                <li><Link to="">iOS app development</Link></li>
                <li><Link to="">Android app development</Link></li>
                <li><Link to="">Flutter app development</Link></li>
            </ul>
            <ul>
                <li><Link to="">Web app development</Link></li>
                <li><Link to="">DevOps services</Link></li>
                <li><Link to="">Legacy soft modernization</Link></li>
            </ul>
            <ul>
                <li><Link to="">Mobile app design</Link></li>
                <li><Link to="">Web app design</Link></li>
                <li><Link to="">UX prototyping</Link></li>
            </ul>
        </div>
    );
}

export default Serviceslinks;